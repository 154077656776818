export const VI_MAIN_URL = 'https://www.virtualinternships.com/';

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const OTP_REGEX = /^\d{0,6}$/;

export const USER_TYPE = 'PARTNER';

export const RESEND_OTP_TIME = 60;

export const STATUS_CODES = {
  UNAUTHORIZED_401: 401,
  UNAUTHORIZED_403: 403,
  INTERNAl_SERVER_ERROR_500: 500,
};
