import { lazy } from 'react';

const ReportDetails = lazy(() => import('../pages/reportDetails'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const AccountSettings = lazy(() => import('../pages/accountSettings'));

export const protectedRoutesWithHeader = [
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'report-details/:id',
    element: <ReportDetails />,
  },
  {
    path: 'account-settings',
    element: <AccountSettings />,
  },
];
