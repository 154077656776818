import { Suspense, useEffect, lazy, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import ContentDefaultLoader from './components/loaders/DefaultContentLoaders';
import { router } from './routes';
const InternalServerError = lazy(() => import('./components/errorFallbacks/InternalServerError'));

import { checkToken, getCheckTokenState } from './reduxToolkit/slices/auth/checkTokenSlice';
import { setTokenDetails } from './reduxToolkit/slices/auth/tokenSlice';
import { AppDispatch } from './reduxToolkit/store';
import { injectAsyncReducersAndMiddlewaresInStore } from './reduxToolkit/injectAsyncReducersAndMiddlewares';

const InternalServerErrorWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <InternalServerError />
  </Suspense>
);

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  const checkTokenStatus = useSelector(getCheckTokenState);

  useEffect(() => {
    (async () => {
      await injectAsyncReducersAndMiddlewaresInStore();
      dispatch(checkToken());
    })();
  }, []);

  useEffect(() => {
    if (checkTokenStatus?.status === 'Success') {
      const tokenDetails = checkTokenStatus?.response?.payload;
      dispatch(setTokenDetails(tokenDetails));
      setIsLoading(false);
    }
    if (checkTokenStatus?.status === 'Failed') {
      if (
        !(
          checkTokenStatus?.response?.data?.message?.includes('Unauthorized') ||
          checkTokenStatus?.response?.data?.data?.message?.includes('Unauthorized')
        )
      )
        dispatch(setTokenDetails(null));
      setIsLoading(false);
    }
  }, [checkTokenStatus]);

  if (isLoading) return ContentDefaultLoader();

  return (
    <Sentry.ErrorBoundary fallback={<InternalServerErrorWithSuspense />}>
      <Suspense fallback={ContentDefaultLoader()}>
        <RouterProvider router={router} />
        <ToastContainer />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
