import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { configureAppStore, setStore } from './dynamicReducers';
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureAppStore(persistedReducer);

setStore(store);

export { store };

export type RootState = ReturnType<typeof store.getState>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppDispatch = typeof store.dispatch | any;
