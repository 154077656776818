import Skeleton from '@mui/material/Skeleton';

function ContentDefaultLoader() {
  return (
    <div className='flex gap-4 flex-col p-16 w-fill-available'>
      <Skeleton className='min-w-[400px]' variant='rectangular' height={100} />
      <Skeleton className='min-w-[400px]' variant='rectangular' height={100} />
      <Skeleton className='min-w-[400px]' variant='rectangular' height={100} />
      <Skeleton className='min-w-[400px]' variant='rectangular' height={100} />
    </div>
  );
}

export default ContentDefaultLoader;
