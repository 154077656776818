import { lazy } from 'react';

const BasicDetails = lazy(() => import('../pages/basicDetails'));

export const partnerSignUpRoutes = [
  {
    path: 'basic-details',
    element: <BasicDetails />,
  },
];
