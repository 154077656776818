import { injectMiddlewares, injectReducers } from './dynamicReducers';

export const injectAsyncReducersAndMiddlewaresInStore = async () => {
  const { default: checkTokenReducer } = await import('./slices/auth/checkTokenSlice');
  const { default: getLoggedInUserDetailsReducer } = await import(
    './slices/partnerPoc/getLoggedInUserDetailsSlice'
  );
  const { getWeeklyFeedbackDetails } = await import(
    './slices/partnerDashboard/weeklyFeedback/getWeeklyFeedbackDetailsSlice'
  );
  const { getWeeklyFeedbackById } = await import(
    './slices/partnerDashboard/weeklyFeedback/getWeeklyFeedbackByIdSlice'
  );
  const { getInternProfileById } = await import(
    './slices/partnerDashboard/getInternProfileByIdSlice'
  );
  const { getCountryList } = await import('./slices/common/getCountryListSlice');
  const { getCareerFields } = await import('./slices/common/getCareerFieldsSlice');
  const { pocDetails } = await import('./slices/partnerPoc/pocDetailsSlice');
  const { partnerDashboard } = await import('./slices/partnerDashboard/partnerDashboardSlice');
  const { getOfferById } = await import('./slices/partnerDashboard/getOfferByIdSlice');

  const asyncReducers = {
    [getWeeklyFeedbackDetails.reducerPath]: getWeeklyFeedbackDetails.reducer,
    [getWeeklyFeedbackById.reducerPath]: getWeeklyFeedbackById.reducer,
    [getOfferById.reducerPath]: getOfferById.reducer,
    [getInternProfileById.reducerPath]: getInternProfileById.reducer,
    [getCountryList.reducerPath]: getCountryList.reducer,
    [getCareerFields.reducerPath]: getCareerFields.reducer,
    [pocDetails.reducerPath]: pocDetails.reducer,
    [partnerDashboard.reducerPath]: partnerDashboard.reducer,
    checkTokenReducer: checkTokenReducer,
    getLoggedInUserDetailsReducer: getLoggedInUserDetailsReducer,
  };

  const asyncMiddlewares = [
    getWeeklyFeedbackDetails.middleware,
    getWeeklyFeedbackById.middleware,
    getOfferById.middleware,
    getInternProfileById.middleware,
    getCountryList.middleware,
    getCareerFields.middleware,
    pocDetails.middleware,
    partnerDashboard.middleware,
  ];

  injectReducers(asyncReducers);
  injectMiddlewares(asyncMiddlewares);
};
