import { axiosInstance as axios } from '../axios/axiosInterceptors';
import { notify } from '../components/notificationToast';

export const logout = () => {
  try {
    axios({
      method: 'get',
      url: 'api/logout',
    });
  } catch (error) {
    notify({ message: 'logout failed' });
  }
};
