import {
  Reducer,
  configureStore,
  EnhancedStore,
  PreloadedStateShapeFromReducersMapObject,
  Middleware,
  createDynamicMiddleware,
  MiddlewareApiConfig,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createRootReducer, RootState } from './rootReducer';

interface AsyncReducers {
  [key: string]: Reducer;
}

interface StoreWithAsyncReducersAndMiddlewares extends EnhancedStore {
  asyncReducers: AsyncReducers;
  dynamicMiddleware: Middleware[] | undefined;
}

let store: StoreWithAsyncReducersAndMiddlewares;

export const setStore = (reduxStore: StoreWithAsyncReducersAndMiddlewares) => {
  store = reduxStore;
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [],
};

const dynamicMiddleware = createDynamicMiddleware();

const { addMiddleware } = dynamicMiddleware;

const addAppMiddleware = addMiddleware.withTypes<MiddlewareApiConfig>();

export const injectReducers = (reducers: { [key: string]: Reducer }) => {
  if (store) {
    const existingReducers = store.asyncReducers || {};
    store.asyncReducers = { ...existingReducers, ...reducers };
    const rootReducer = createRootReducer(store.asyncReducers);
    store.replaceReducer(persistReducer(persistConfig, rootReducer));
  }
};

export const injectMiddlewares = (middlewares: Middleware[]) => {
  if (store) {
    middlewares.forEach((middleware) => addAppMiddleware(middleware));
  }
};

export const configureAppStore = (
  rootReducer: Reducer,
  preloadedState?: PreloadedStateShapeFromReducersMapObject<RootState>,
): StoreWithAsyncReducersAndMiddlewares => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(dynamicMiddleware.middleware),
  }) as StoreWithAsyncReducersAndMiddlewares;

  store.asyncReducers = {};
  store.dynamicMiddleware = undefined;
  setStore(store);
  return store;
};
