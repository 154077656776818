import storage from 'redux-persist/lib/storage';
import { AnyAction, Reducer, UnknownAction, combineReducers } from '@reduxjs/toolkit';

import errorStatusReducer from './slices/error/errorStatusSlice';
import tokenReducer from './slices/auth/tokenSlice';

export const staticReducers = {
  errorStatusReducer,
  tokenReducer,
};

const appReducer = combineReducers({ ...staticReducers });

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: UnknownAction) => {
  if (action.type === 'clear/clearResults') {
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export const createRootReducer = (asyncReducers: { [key: string]: Reducer }) => {
  const appReducer = combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });

  // Handle the reset action
  return (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === 'clear/clearResults') {
      storage.removeItem('persist:root');
      state = undefined;
    }
    return appReducer(state, action);
  };
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
