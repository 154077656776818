import { datadogLogs, StatusType } from '@datadog/browser-logs';

import { setTokenDetails } from '../reduxToolkit/slices/auth/tokenSlice';
import { clearResults } from '../reduxToolkit/slices/reset/clearStoreSlice';
import { AppDispatch } from '../reduxToolkit/store';

import { logout } from '../services/general';

import { configs } from '../constants/configs';

export const getCdnUrl = (url: string) => {
  return configs.VITE_APP_ASSET_BASE_URL + url;
};

export const generateErrorStatusKey = (
  method: string,
  url: string,
  params: Record<string, string> = {},
  body = null,
) => {
  const paramsString =
    Object.keys(params)
      .sort()
      .map((key) => `${key}=${params[key]}`)
      .join('&') || '';

  const bodyString = body ? JSON.stringify(body) : '';

  return `${method?.toUpperCase()}:${url}?${paramsString}${bodyString}`;
};

export const logTrigger = (
  message: string,
  jsonAttributes = {},
  status: StatusType | undefined,
  error: Error,
) => {
  datadogLogs.logger.log(message, jsonAttributes, status, error);
};

export const handleLogout = async (dispatch: AppDispatch) => {
  dispatch(setTokenDetails(null));
  dispatch(clearResults());

  sessionStorage.clear();
  localStorage.clear();

  await logout();
};
