import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { protectedRoutesWithHeader } from './protectedRoutes';
import { partnerSignUpRoutes } from './partnerSignUpRoutes';

// Layouts
const RootLayout = lazy(() => import('../layouts/RootLayout'));

// Route Handlers
const ProtectedRouteHandler = lazy(() => import('./ProtectedRouteHandler'));
const BaseRouteHandler = lazy(() => import('./BaseRouteHandler'));
const SignUpRouteHandler = lazy(() => import('./SignUpRouteHandler'));

// Pages
const Login = lazy(() => import('../pages/login'));
const Notfound = lazy(() => import('../components/errorFallbacks/NotFound'));

// components
const RouterErrorHandler = lazy(() => import('../components/errorFallbacks/RouterErrorHandler'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <RouterErrorHandler />,
    children: [
      {
        element: <BaseRouteHandler />,
        children: [
          {
            index: true,
            element: <Login />,
          },
          {
            path: '/login',
            element: <Login />,
          },
        ],
      },
      {
        element: <ProtectedRouteHandler showHeader={true} />,
        children: protectedRoutesWithHeader,
      },
      {
        element: <SignUpRouteHandler />,
        children: partnerSignUpRoutes,
      },
      {
        path: 'notfound',
        element: <Notfound />,
      },
      {
        path: '*',
        element: <Notfound />,
      },
    ],
  },
]);
