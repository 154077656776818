import { toast, ToastPosition } from 'react-toastify';

import { TOAST_POSITIONS, TOAST_TYPES } from './constants/notificationToast.constants';

import 'react-toastify/dist/ReactToastify.css';

interface INotify {
  type?: string;
  message: string;
  errorPosition?: ToastPosition;
  className?: string;
}

export const notify = ({
  type = TOAST_TYPES.ERROR,
  message,
  errorPosition = TOAST_POSITIONS.TOP_RIGHT as ToastPosition,
  className,
}: INotify) => {
  toast.dismiss();
  switch (type) {
    case TOAST_TYPES.ERROR:
      toast.error(message, {
        position: errorPosition,
      });
      break;
    case TOAST_TYPES.SUCCESS:
      toast.success(message, {
        position: errorPosition,
      });
      break;
    case TOAST_TYPES.WARN:
      toast.warn(message, {
        position: errorPosition,
      });
      break;
    case TOAST_TYPES.INFO:
      toast.info(message, {
        position: errorPosition,
      });
      break;
    default:
      toast(message, {
        position: errorPosition,
        className,
      });
  }
};
