import { createSlice } from '@reduxjs/toolkit';

interface IErrorState {
  errors: {
    [key: string]: {
      statusCode: number;
    };
  };
}

const initialState: IErrorState = {
  errors: {},
};

const errorStatusSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setErrorStatus: (state, action) => {
      const { key, statusCode } = action.payload;
      state.errors[key] = { statusCode };
    },
  },
});

// to access the error status use
// const apiErrorStatusCode = (state: RootState, url: string) => state.errorStatusReducer.errors[url].statusCode

export const { setErrorStatus } = errorStatusSlice.actions;

export default errorStatusSlice.reducer;
