import { createSlice } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
  name: 'token',
  initialState: { tokenDetails: null },
  reducers: {
    setTokenDetails: (state, action) => {
      state.tokenDetails = action.payload || null;
    },
    clearTokenDetails: (state) => {
      state.tokenDetails = null;
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTokenDetailsState = (state: { [key: string]: any }) =>
  state.tokenReducer.tokenDetails;
export const { setTokenDetails, clearTokenDetails } = tokenSlice.actions;
export default tokenSlice.reducer;
