import './wdyr';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { datadogLogs } from '@datadog/browser-logs';

import App from './App.tsx';

import { store } from './reduxToolkit/store.ts';

import { initializeClarity } from './utils/clarity.ts';

import { configs } from './constants/configs.ts';

import './index.css';

const persistor = persistStore(store);

if (configs.VITE_APP_CLUSTER === 'prod' && configs.VITE_APP_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: configs.VITE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    sessionSampleRate: 100,
    env: `${configs.VITE_APP_CLUSTER}:partners-app`,
    service: 'fe-partners-app',
  });
}

if (configs.VITE_APP_CLUSTER !== 'local' && configs.VITE_APP_CLUSTER !== 'development') {
  Sentry.init({
    dsn: 'https://096aa9f8a1da590dbb4015dadc9d91c3@o4504966076104704.ingest.us.sentry.io/4507649742077952',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        autoInject: false,
      }),
    ],
    environment: configs.VITE_APP_CLUSTER,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Object captured as exception',
      "TypeError: can't access dead object",
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      'NotFoundError: The object can not be found here.',
      "ReferenceError: Can't find variable",
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    beforeSend: (event, hint) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error: any = hint?.originalException;
      if (
        error &&
        typeof error !== 'string' &&
        error?.message &&
        (error?.message?.includes('chunk') ||
          error?.message?.includes('Chunk') ||
          error?.message?.includes('Unexpected token'))
      )
        return null;

      return event;
    },
  });
}

if (configs.VITE_APP_CLUSTER === 'prod') {
  initializeClarity();
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
