import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash/get';

import { store } from '../reduxToolkit/store';
import { clearTokenDetails } from '../reduxToolkit/slices/auth/tokenSlice';
import { clearResults } from '../reduxToolkit/slices/reset/clearStoreSlice';
import { setErrorStatus } from '../reduxToolkit/slices/error/errorStatusSlice';

import { generateErrorStatusKey, logTrigger } from '../utils/utilities';

import { logout } from '../services/general';

import { configs } from '../constants/configs';
import { STATUS_CODES } from '../constants/general';

const BASE_URL = configs.VITE_APP_BASE_URL;

const generateRequestId = () => {
  return uuidv4();
};

// Create axios instance
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const requestId = generateRequestId();
    config.headers['X-Request-ID'] = requestId;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const handleLogOut = async ({ clearStore }: { clearStore: boolean }) => {
  sessionStorage.clear();
  localStorage.clear();
  try {
    await logout();
    store.dispatch(clearTokenDetails());
    if (clearStore) {
      store.dispatch(clearResults());
    }
  } catch (error) {
    logTrigger(
      _get(error, 'message', ''),
      'User logged out due to invalid token',
      'error',
      error as Error,
    );
  }
};

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response?.status === STATUS_CODES.UNAUTHORIZED_401) {
      handleLogOut({ clearStore: true });
    }
    return response;
  },
  (error) => {
    const requestMethod = error?.response?.config?.method;
    const requestUrl = error?.response?.config?.url;
    const requestParams = error?.response?.config?.params;
    const requesBody = error?.response?.config?.data;
    const responseStatusCode = error?.response?.status;

    const errorStatusKey = generateErrorStatusKey(
      requestMethod,
      requestUrl,
      requestParams,
      requesBody,
    );

    logTrigger(
      error?.message,
      { errorConfig: error?.response?.config, errorCode: error?.code },
      'error',
      error,
    );

    if (responseStatusCode === STATUS_CODES.UNAUTHORIZED_401) {
      handleLogOut({ clearStore: true });
    }
    if (responseStatusCode === STATUS_CODES.UNAUTHORIZED_403) {
      store.dispatch(setErrorStatus({ key: errorStatusKey, statusCode: responseStatusCode }));
    }
    if (responseStatusCode === STATUS_CODES.INTERNAl_SERVER_ERROR_500) {
      store.dispatch(setErrorStatus({ key: errorStatusKey, statusCode: responseStatusCode }));
    }

    return Promise.reject(error);
  },
);

interface ICustomBaseQuery {
  url: string;
  method: string;
  params?: Record<string, unknown>;
  body?: Record<string, unknown>;
}

export const customBaseQuery = async ({ url, method, params, body }: ICustomBaseQuery) => {
  try {
    let result;

    if (method?.toUpperCase() === 'GET') {
      result = await axiosInstance({ url, method, params });
    } else {
      result = await axiosInstance({ url, method, data: body });
    }

    return { data: result.data };
  } catch (error) {
    return { error };
  }
};
