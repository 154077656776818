import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../axios/axiosInterceptors';

const CheckTokenURL = '/api/check-token';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

interface CheckTokenState {
  status: 'idle' | 'Pending' | 'Success' | 'Failed';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: any;
}

interface CheckTokenResponse {
  isSuccess: boolean;
}

interface RejectValue {
  response: string;
}

export const checkToken = createAsyncThunk('checkToken', async (request, { rejectWithValue }) => {
  const result = await axiosInstance
    .get(CheckTokenURL)
    .then((response) => response?.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const checkTokenSlice = createSlice({
  name: 'CheckToken',
  initialState,
  reducers: {
    resetTokenStatus: (state) => ({ ...state, status: 'idle' }),
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraReducers(builder: any) {
    builder
      /* generate check token state handling */
      .addCase(checkToken.pending, (state: CheckTokenState) => {
        state.status = 'Pending';
      })
      .addCase(
        checkToken.fulfilled,
        (state: CheckTokenState, action: PayloadAction<CheckTokenResponse>) => {
          state.status = action.payload?.isSuccess ? 'Success' : 'Failed';
          state.response = action.payload;
        },
      )
      .addCase(
        checkToken.rejected,
        (state: CheckTokenState, action: PayloadAction<RejectValue>) => {
          state.status = 'Failed';
          state.response = action?.payload.response;
        },
      );
  },
});

export const getCheckTokenState = (state: { checkTokenReducer: CheckTokenState }) =>
  state.checkTokenReducer;
export const { resetTokenStatus } = checkTokenSlice.actions;

export default checkTokenSlice.reducer;
